.bl-socialmedia {
    h1 {
        @include typo-subtitle;

        padding-bottom:0.2em;
        border-bottom:var(--borderwidth) solid #000000;
        margin-bottom:0.4em;

        text-align: center;
        width:fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .items {
        display:grid;
        gap:1px;
        grid-template-columns: repeat(2, 1fr);
        @include breakpoint(medium) {
            grid-template-columns: repeat(6, 1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(6, 1fr);
        }
    
        img {
            aspect-ratio: 1;
            display: block;
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }
    }
}