.off-canvas {
  display: none;

  z-index:10;

  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;

  background-color: #FFFFFF;

  .ctrmenu {
    position: absolute;
    inset:0;

    display:flex;
    justify-content: center;
    align-items: center;
  }

  ul.menu {
    @include reset-list;

    text-align: center;

    a {
      padding-top:2em;
      padding-bottom:2em;
      min-width:rem-calc(150);
      display: block;
      color:inherit;
      text-transform: uppercase;
    }
    li {
      border-top:var(--borderwidth) solid #000000;
      &.last {
        border-bottom:var(--borderwidth) solid #000000;
      }
    }
  }

  .wconstrain {
    position: relative;
  }

  .closebutton {
    cursor: pointer;
    
    width:rem-calc(36);

    position: absolute;
    right:0;
    top:var(--buttontop);
  }
}
