footer {
  margin-top: 8em;

  background-color: #000000;
  color:white;
  padding: rem-calc(30 0);
  a {
    color: inherit;
    &:hover {
    }
  }

  .ctrlogo img {
    display: block;
    width:rem-calc(100);
  }

  .ctrsocial {
    display:flex;
    gap:rem-calc(20);
    img {
      display: block;
      width:rem-calc(40);
    }
  }

  @include breakpoint(large) {
    .wconstrain {
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
    .col1, .col2 {
      display: flex;
      align-items:center;
    }
    .col1 {
      gap:rem-calc(100);
    }
    .col2 {
      gap:rem-calc(70);
    }
  }

  @include breakpoint(medium down) {
    .ctraddress, .ctrsocial, .ctrlinks {
      margin-top:1em;
    }
  }
}

