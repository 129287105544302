@include breakpoint(large) {
    .vscreencenter {
        min-height:100vh;
        display:flex;
        flex-direction:column;
        justify-content:center;
    }
}
.ctrprojektdetail-backbutton {
    .wconstrain {
        position: relative;
    }
    .backbutton {
        display: block;
        cursor: pointer;
        z-index:10;

        width:rem-calc(36);
    }
    @include breakpoint(large) {
        .backbutton {
            position: absolute;
            right:0;
            top:var(--buttontop);
        }
    }
    @include breakpoint(medium down) {
        margin-top:2em;
        .backbutton {
            margin-left:auto;
        }
    }
}
.projektdetail {
    padding-top:4.5em;
    padding-bottom:4.5em;
    @include breakpoint(medium down) {
        padding-top:2em;
        padding-bottom:2em;
    }

    h2 {
        @include typo-base;
        text-transform: uppercase;

        padding-bottom:0.8em;
        border-bottom: var(--borderwidth) solid #000000;
        margin-bottom:-0.1em;

        width: fit-content;
    }
    h1 {
        @include typo-maintitle;
    }

    .text p:last-child {
        margin-bottom: 0;
    }

    .keywords {
        text-transform: uppercase;

        margin-top:0.8em;
        border-top:var(--borderwidth) solid #000000;
        padding-top:0.8em;

        width: fit-content;
    }
    
    @include breakpoint(large) {
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap:rem-calc(100);

        position: relative;

        .ctrimage {
            width:50%;
        }
        .ctrinfo {
            width:35%;
            padding-right:3%;
        }
    }
    @include breakpoint(medium down) {
        .ctrinfo {
            margin-top:2em;
        }
    }
}