.bl-sitetitle {
    padding-top: 13em;
    padding-bottom: 13em;
    @include breakpoint(medium down) {
        padding-top: 8em;
        padding-bottom: 8em;
    }

    text-align: center;

    h1 {
        @include typo-maintitle;
        padding-bottom:0.15em;
        border-bottom: var(--borderwidth) solid #000000;
        margin-bottom:0.4em;
        
        width:fit-content;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(medium) {
            br {
                display:none;
            }
        }
    }
    .text {
        max-width:rem-calc(756);
        margin-left: auto;
        margin-right: auto;
    }
}