.bl-button {
    border-top:var(--borderwidth) solid #000000;
    border-bottom:var(--borderwidth) solid #000000;
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    a {
        display: block;
        padding-top: 2.4em;
        padding-bottom: 2.4em;
        min-width:rem-calc(150);
        color:inherit;
        
        text-transform: uppercase;
    }
}