.bl-hero {
    height:100vh;
    height:100lvh;

    position: relative;

    padding-top:var(--buttontop);

    background-color: #eee;
    background-size: cover;
    background-position: center bottom;

    .wconstrain {
        position: relative;
    }
    .ctrlogo {
        width:rem-calc(143);
        margin-left:auto;
        margin-right: auto;
    }
    .scrolldownarrow {
        position: absolute;
        top:100vh;
        top:100svh;
        left:50%;
        transform: translate(-50%, -130%);
        width:rem-calc(25);
        cursor: pointer;
    }
}

.ctrmenubutton {
    position: relative;
    z-index: 1;

    /*
    position: fixed;
    left: 0;
    right: 0;
    */

    .menubutton {
        width:rem-calc(36);

        position: absolute;
        right:0;
        top:var(--buttontop);

        cursor: pointer;
    }
}