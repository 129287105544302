//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    margin-top:2em;
}

//Spezifische Abstaende
/*
.ws1 {
    .bl-richtext + .bl-slider {
        margin-top:2em;
    }
    .bl-slider + .bl-linkboxes {
        margin-top:2em;
    }
}
*/

.cblock + .bl-button {
    margin-top: 8em;
    margin-bottom: 8em;
}

.bl-textwithimage + .bl-textwithimage {
    margin-top:20em;
    @include breakpoint(medium down) {
        margin-top:12em;
    }
}
