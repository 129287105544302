.h-fixed-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  transition: all 0.2s ease-in-out;

  padding:rem-calc(20 0);

  .headerinner {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width:rem-calc(200);
    @include breakpoint (small down) {
      width:rem-calc(125);
    }
  }
  
  @include shadow(0 2px 7px 0 rgba($black, 0.1));

  &.opaque {
    //@include shadow(0 2px 7px 0 rgba($black, 0.1));

    /*.logo {
      width:rem-calc(200);
    }*/
  }

}
