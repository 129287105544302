.bl-quote {
    padding-top: 7em;
    padding-bottom: 7em;

    max-width:rem-calc(1114);
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    hr {
        max-width: rem-calc(130);
        border:none;
        border-top:var(--borderwidth) solid #000000;
    }
    .quote {
        @include typo-subtitle;
    }
    .author {
        text-transform: uppercase;
        margin-top: 0.9em;
        margin-bottom:1.75em;
    }
}